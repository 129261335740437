export const PreMeetingSurveyPages = {
  initialQuestion: 'initialQuestion',
  selfServiceFlow: 'selfServiceFlow',
  customerDetails: 'customerDetails'
} as const;

export type PreMeetingSurveyPage =
  typeof PreMeetingSurveyPages[keyof typeof PreMeetingSurveyPages];

export const RemindOrResendValues = {
  remind: 'remind',
  resend: 'resend'
} as const;

export type RemindOrResendValue =
  typeof RemindOrResendValues[keyof typeof RemindOrResendValues];
