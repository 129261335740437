import * as R from 'ramda';
import { isNil, path, split } from 'ramda';

import { ClientTypes } from 'features/shared/constants/session';
import { filterNil } from 'features/shared/utils/filters';
import { RuleTypes } from 'features/sharedModules/declarativeForm/constants';

// TODO cover by tests
export const calculateFinancialSituationProgress = ({
  formErrors,
  values,
  amountForAdviceCurrentSum,
  financialSituationConfig,
  amountForAdviceAdvisorNotesConfig,
  clientType,
  errors,
  isFollowUpFlow
}) => {
  const {
    assets,
    debt,
    liquidity,
    accountingFigures,
    oneFieldInAssetsRequired,
    oneFieldInDebtRequired,
    isAmountForAdviceEnabled
  } = financialSituationConfig;

  const allFields = [
    ...(assets[clientType] || []),
    ...(debt[clientType] || []),
    ...(liquidity[clientType] || []),
    ...(clientType === ClientTypes.company ? accountingFigures || [] : [])
  ];
  const requiredFieldsIds = allFields
    .filter(({ enabled, required }) => enabled && required)
    .map(({ id }) => id);

  const key =
    clientType === ClientTypes.company
      ? 'companyFinancialSituation'
      : 'personFinancialSituation';
  const amountOfFilledRequiredFields = requiredFieldsIds.reduce(
    (prev, curr) => {
      const value = R.view(R.lensPath([key, curr]), values);
      const hasValue =
        value && Array.isArray(value)
          ? value.some(d => filterNil(d?.value))
          : value !== undefined && value !== null;
      if (hasValue) {
        return prev + 1;
      }

      return prev;
    },
    0
  );

  const expectingFinancialChangesNoteError =
    !R.isNil(formErrors.expectingFinancialChangesNote) ||
    R.isNil(values[key]?.expectingFinancialChanges);

  const amountForAdviceError =
    isAmountForAdviceEnabled && amountForAdviceCurrentSum === 0;

  const isAmountForAdviceVisible =
    isAmountForAdviceEnabled &&
    assets[clientType]?.some(
      ({ showInAmountForAdvice, id }) =>
        showInAmountForAdvice &&
        (clientType === ClientTypes.person
          ? !isNil(values.personFinancialSituation?.[id]?.[0]?.value)
          : !isNil(values.companyFinancialSituation?.[id]?.[0]?.value))
    );

  const isAmountForAdviceAdvisorNotesRequired =
    isAmountForAdviceEnabled &&
    amountForAdviceAdvisorNotesConfig?.required &&
    amountForAdviceAdvisorNotesConfig?.enabled;

  const amountForAdviceAdvisorNotesError =
    isAmountForAdviceEnabled && !values.amountForAdviceAdvisorNotes?.length;

  const amountOfMissingFollowUpExistingPortfolioAssetClasses = (
    isFollowUpFlow && errors?.followUpTable ? errors.followUpTable : []
  ).length;

  return (
    ((formErrors.assetsError || !oneFieldInAssetsRequired ? 0 : 1) +
      (formErrors.debtError || !oneFieldInDebtRequired ? 0 : 1) +
      (expectingFinancialChangesNoteError ? 0 : 1) +
      (amountForAdviceError || !isAmountForAdviceVisible ? 0 : 1) +
      (amountForAdviceAdvisorNotesError ||
      !isAmountForAdviceAdvisorNotesRequired
        ? 0
        : 1) +
      amountOfFilledRequiredFields) /
    (oneFieldInAssetsRequired +
      oneFieldInDebtRequired +
      requiredFieldsIds.length +
      (isAmountForAdviceVisible ? 1 : 0) +
      (isAmountForAdviceAdvisorNotesRequired ? 1 : 0) +
      (!R.isNil(values[key]?.expectingFinancialChanges) ? 1 : 0) +
      amountOfMissingFollowUpExistingPortfolioAssetClasses)
  );
};

// TODO cover by tests
export const isFieldProgressCalculationRequired = (rule, values) => {
  if (rule.type === RuleTypes.allFieldsEqual) {
    return R.all(f => R.path(R.split('.', f[0]), values) === f[1], rule.fields);
  }

  if (rule.type === RuleTypes.fieldChecked) {
    return R.all(
      f => R.includes(f[1], R.path(R.split('.', f[0]), values) || []),
      rule.fields
    );
  }

  if (rule.type === RuleTypes.never) {
    return false;
  }

  if (rule.type === RuleTypes.buttonSwitchChecked) {
    return R.all(f => R.path(R.split('.', f[0]), values) === true, rule.fields);
  }

  if (rule.type === RuleTypes.buttonSwitchUnchecked) {
    return R.all(
      f => R.path(R.split('.', f[0]), values) === false,
      rule.fields
    );
  }

  return false;
};

// TODO cover by tests
const calculateFieldsProgress = (fields, errors) =>
  fields?.length > 0
    ? R.length(
        R.filter(f => R.isNil(R.path(R.split('.', f[0]), errors)), fields)
      ) / R.length(fields)
    : 1;

export const calculateCustomFieldsTabProgress = ({
  fields,
  values,
  errors
}) => {
  if (fields.length === 0) {
    return 1;
  }

  const requiredFields = fields
    .filter(({ required }) => required)
    .map(({ name }) => [name]);

  const textFields = fields
    .map(field =>
      field.items
        ? field.items
            .filter(a => {
              const value = R.path(field.name.split('.'), values);
              return (
                (Array.isArray(value)
                  ? value.includes(a.activeValue)
                  : a.activeValue === value) &&
                a.textField &&
                a.textField?.required
              );
            })
            .map(a => a.textField.name)
        : []
    )
    .filter(f => f.length > 0);

  const fieldsWithTextFields = [...requiredFields, ...textFields];

  return calculateFieldsProgress(fieldsWithTextFields, errors);
};

export const calculateOrderInformationPage2Progress = ({
  sections,
  advisorNotesConfig,
  roboAdviceFormState
}) => {
  const requiredFields =
    sections
      ?.filter(({ isEnabled }) => isEnabled)
      .map(({ items }) => items)
      .flat()
      .filter(({ required }) => required) || [];

  const requiredTextFields =
    sections
      ?.map(({ items }) => items)
      .flat()
      .map(field =>
        field.items
          ? field.items.filter(a => {
              const value = path(
                field.name.split('.'),
                roboAdviceFormState.values
              );
              return (
                (Array.isArray(value)
                  ? value.includes(a.activeValue)
                  : a.activeValue === value) &&
                a.textField &&
                a.textField?.required
              );
            })
          : []
      )
      .filter(f => f.length > 0) || [];

  const requiredSections = sections?.filter(
    ({ isEnabled, required }) => isEnabled && required
  );

  const maxProgress =
    requiredFields?.length +
    requiredTextFields?.length +
    requiredSections?.length +
    (advisorNotesConfig?.enabled && advisorNotesConfig?.required ? 1 : 0);

  const advisorNotesError =
    advisorNotesConfig?.enabled &&
    advisorNotesConfig?.required &&
    !roboAdviceFormState.values.orderInformationPage2AdvisorNotes?.length;

  const numberOfSectionsErrors =
    requiredSections?.filter(
      section =>
        !section.items.some(item => {
          const value = path(
            split('.', item?.name),
            roboAdviceFormState.values
          );
          return !isNil(value) && value !== '';
        })
    )?.length || 0;

  const numberOfFieldsErrors =
    [...requiredFields, ...requiredTextFields].flat().filter(field => {
      return (
        !isNil(
          path(
            split('.', field.textField?.name || ''),
            roboAdviceFormState.errors
          )
        ) ||
        !isNil(path(split('.', field?.name || ''), roboAdviceFormState.errors))
      );
    })?.length || 0;

  return (
    (maxProgress -
      numberOfSectionsErrors -
      numberOfFieldsErrors -
      (advisorNotesError ? 1 : 0)) /
    maxProgress
  );
};

export const calculateInstrumentsTabProgress = ({ values, errors }) => {
  let progress = 0;
  let maxProgress = 3;

  if (values) {
    if (values.instrumentsSelected && values.instrumentsSelected.length > 0) {
      progress += 1;
      if (
        !values.instrumentsSelected.some(
          instrument => !instrument.initialDeposit && !instrument.monthlySaving
        )
      ) {
        progress += 1;
      }
    }

    if (
      values.instrumentsAdvisorNotes &&
      R.length(values.instrumentsAdvisorNotes) >= 25
    ) {
      progress += 1;
    }
  }

  if (errors?.instruments && errors.instruments.length) {
    maxProgress++;
  }

  return progress / maxProgress;
};
