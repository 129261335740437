import axios, { CancelTokenSource } from 'axios';
import { isNil, isEmpty, omit } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCostChartStore } from '../../../costChart';
import { createAnalyzeCost } from '../../../shared/api';
import { costForm } from '../../services/costForm';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import {
  getHorizonAnalysisProjectionYearsValue,
  getIsFormComplete
} from '../../services/selectors.js';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  CostChartPortfolio,
  CostGoalData,
  FundCostOverride,
  OriginalGoalData
} from 'features/shared/types/costChartTypes';
import { formatDateForAPI } from 'features/shared/utils/dateTime.js';
import { omitNilProps } from 'features/shared/utils/omitNilProps';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadCostChartData() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const {
    roboAdviceForm: {
      proposal: {
        isOverrideFundManagementFeeEnabled,
        isOverrideFundTransactionCostEnabled,
        isOverrideFundPurchasingFeeEnabled,
        isOverrideFundReturnCommissionPaidToClientEnabled,
        isOverrideSummaryPlatformFeeEnabled,
        isOverrideSummaryCustodyFeeEnabled,
        isOverrideSummaryCustodyFeeAmountEnabled,
        isOverrideSummaryPortfolioAdvisoryOnboardingFeeEnabled,
        isOverrideSummaryPortfolioAdvisoryPurchasingFeeEnabled
      }
    },
    timeHorizonConfig,
    tenantSettings: { fundNameForPresentation },
    analyticsComponents: { multiGoalCostEnabled }
  } = useCustomerConfig();
  const portfolioChartStore = usePortfolioChartStore();

  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();

  const readCostChartData = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const proposalPageStore = useProposalPageStore.getState();
    const costChartStore = useCostChartStore.getState();
    const { goals: storeGoals } = useGoalsStore.getState();

    const isFormComplete = getIsFormComplete();
    if (!isFormComplete) {
      costChartStore.resetCostData();
      proposalPageStore.setPageStatus('readCostChartData', PageStatuses.failed);

      return;
    }

    try {
      proposalPageStore.setPageStatus(
        'readCostChartData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const isUpdated =
        !!proposalPageStore.goalsToUpdate.readCostChartData.length;

      const goals = isUpdated
        ? proposalPageStore.goalsToUpdate.readCostChartData
        : storeGoals;

      const { values: costFormValues } = costForm.getState();

      const {
        values: { clientInformation }
      } = clientForm.getState();

      const dateOfBirth = clientInformation?.additionalData?.dateOfBirth
        ? formatDateForAPI(clientInformation.additionalData.dateOfBirth)
        : undefined;

      const areCostsOverwritten =
        ((!isNil(costFormValues.overrideCost) &&
          !isEmpty(costFormValues.overrideCost)) ||
          (!isNil(costFormValues.overrideFundCosts) &&
            !isEmpty(costFormValues.overrideFundCosts))) &&
        (isOverrideFundManagementFeeEnabled ||
          isOverrideFundTransactionCostEnabled ||
          isOverrideFundPurchasingFeeEnabled ||
          isOverrideFundReturnCommissionPaidToClientEnabled ||
          isOverrideSummaryPlatformFeeEnabled ||
          isOverrideSummaryCustodyFeeEnabled ||
          isOverrideSummaryCustodyFeeAmountEnabled ||
          isOverrideSummaryPortfolioAdvisoryOnboardingFeeEnabled ||
          isOverrideSummaryPortfolioAdvisoryPurchasingFeeEnabled);

      let goalsData: CostGoalData[] = [];
      let originalGoalsData: OriginalGoalData[] = [];

      for (const goal of goals) {
        const portfolio: CostChartPortfolio = portfolioChartStore
          .getChartData(goal.goalId, goal.data.isPortfolioCustom)
          .categorySelectionPortfolio.map(({ id, name, weight }) => ({
            id,
            name,
            weight
          }));

        const savingsplan = {
          starting_capital:
            (goal.data.firstDeposit ?? 0) + (goal.data.internalHolding ?? 0),
          monthly_savings: goal.data.monthlyDeposit || 0
        };

        const timeHorizon = getHorizonAnalysisProjectionYearsValue({
          timeHorizonConfig,
          timeHorizon: goal.data.timeHorizon
        });

        const payload = {
          portfolio: portfolio.map(p => omit(['name'], p)),
          savingsplan,
          horizon: timeHorizon,
          dateOfBirth
        };

        if (areCostsOverwritten) {
          const costoverride = omitNilProps({
            platform_fee:
              typeof costFormValues.overrideCost?.platformFee === 'number'
                ? (costFormValues.overrideCost.platformFee * 100) / 10000
                : null,
            custody_fee:
              typeof costFormValues.overrideCost?.custodyFeePercent === 'number'
                ? (costFormValues.overrideCost.custodyFeePercent * 100) / 10000
                : null,
            custody_fee_amount: costFormValues.overrideCost?.custodyFeeAmount,
            portfolio_advisory_onboarding_fee:
              typeof costFormValues.overrideCost?.advisoryOnboardingFee ===
              'number'
                ? (costFormValues.overrideCost.advisoryOnboardingFee * 100) /
                  10000
                : null,
            portfolio_advisory_purchasing_fee:
              typeof costFormValues.overrideCost?.advisoryPurchasingFee ===
              'number'
                ? (costFormValues.overrideCost.advisoryPurchasingFee * 100) /
                  10000
                : null
          });

          const overridePortfolio: CostChartPortfolio = portfolio.map(
            portfolio => {
              const fundCostOverride: FundCostOverride = omitNilProps({
                fund_management_fee:
                  typeof costFormValues.overrideFundCosts?.[portfolio.name]
                    ?.fundManagementFee === 'number'
                    ? (costFormValues.overrideFundCosts?.[portfolio.name]
                        .fundManagementFee! *
                        100) /
                      10000
                    : null,
                fund_transaction_cost:
                  typeof costFormValues.overrideFundCosts?.[portfolio.name]
                    ?.fundTransactionCost === 'number'
                    ? (costFormValues.overrideFundCosts?.[portfolio.name]
                        ?.fundTransactionCost! *
                        100) /
                      10000
                    : null,
                fund_purchasing_fee:
                  typeof costFormValues.overrideFundCosts?.[portfolio.name]
                    ?.fundPurchaseFee === 'number'
                    ? (costFormValues.overrideFundCosts?.[portfolio.name]
                        ?.fundPurchaseFee! *
                        100) /
                      10000
                    : null,
                fund_return_commission_paid_to_client:
                  typeof costFormValues.overrideFundCosts?.[portfolio.name]
                    ?.fundReturnCommissionPaidToClient === 'number'
                    ? (costFormValues.overrideFundCosts?.[portfolio.name]
                        ?.fundReturnCommissionPaidToClient! *
                        100) /
                      10000
                    : null
              });

              return isEmpty(fundCostOverride)
                ? portfolio
                : {
                    ...portfolio,
                    fund_cost_override: fundCostOverride
                  };
            }
          );

          const overridePayload = {
            ...payload,
            portfolio: overridePortfolio.map(p => omit(['name'], p))
          };

          const [analyzeCostResponse, analyzeCostOverrideFundResponse] =
            await Promise.all([
              createAnalyzeCost(
                qAuthAccessToken,
                cancelTokenSource.token,
                payload,
                {
                  namespace_id: goal.data.productPlatformNamespace,
                  language: getUserPageLanguage()
                }
              ),
              createAnalyzeCost(
                qAuthAccessToken,
                cancelTokenSource.token,
                isEmpty(costoverride)
                  ? overridePayload
                  : { ...overridePayload, costoverride },
                {
                  namespace_id: goal.data.productPlatformNamespace,
                  language: getUserPageLanguage()
                }
              )
            ]);

          const data = {
            startingCapital:
              savingsplan.starting_capital > 0
                ? savingsplan.starting_capital
                : savingsplan.monthly_savings,
            goalId: goal.goalId,
            i18n
          };

          goalsData = [
            ...goalsData,
            { ...data, portfolio, data: analyzeCostOverrideFundResponse.data }
          ];

          originalGoalsData = [
            ...originalGoalsData,
            {
              ...data,
              portfolio: overridePortfolio,
              data: analyzeCostResponse.data
            }
          ];
        } else {
          const analyzeCostResponse = await createAnalyzeCost(
            qAuthAccessToken,
            cancelTokenSource.token,
            payload,
            {
              namespace_id: goal.data.productPlatformNamespace,
              language: getUserPageLanguage()
            }
          );

          const data = {
            portfolio,
            startingCapital:
              savingsplan.starting_capital > 0
                ? savingsplan.starting_capital
                : savingsplan.monthly_savings,
            data: analyzeCostResponse.data,
            goalId: goal.goalId,
            i18n
          };

          goalsData = [...goalsData, data];
        }
      }

      if (isUpdated) {
        proposalPageStore.setGoalsToUpdate('readCostChartData', []);
        costChartStore.editGoalsData(
          goalsData,
          multiGoalCostEnabled,
          fundNameForPresentation
        );
        if (areCostsOverwritten) {
          isEmpty(costChartStore.originalGoalsData)
            ? costChartStore.addGoalsData(
                originalGoalsData,
                multiGoalCostEnabled,
                fundNameForPresentation,
                'originalGoalsData'
              )
            : costChartStore.editGoalsData(
                originalGoalsData,
                multiGoalCostEnabled,
                fundNameForPresentation,
                'originalGoalsData'
              );
        }
      } else {
        costChartStore.addGoalsData(
          goalsData,
          multiGoalCostEnabled,
          fundNameForPresentation
        );
        if (areCostsOverwritten) {
          costChartStore.addGoalsData(
            originalGoalsData,
            multiGoalCostEnabled,
            fundNameForPresentation,
            'originalGoalsData'
          );
        }
      }

      proposalPageStore.setPageStatus(
        'readCostChartData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        costChartStore.resetCostData();
        proposalPageStore.setPageStatus(
          'readCostChartData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readCostChartData;
}
